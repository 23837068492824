<template>
  <div class="price-lead-time h-full">
    <card title="Select booking scenario" class="w-full border border-radius">
        <div class="flex flex__column w-full">
            <div class="flex flex-wrap mb-8 mt-4 w-full gap-6" :key="`priceleadtime_mode_switch_${componentCounter}`">
                <radio-input
                    v-for="input in radioInputs[$route.name]"
                    :key="input.to"
                    v-model="pltRoute"
                    :input-value="input.to"
                    input-name="plt"
                    style="width: calc(50% - 12px)"
                    class="py-2 px-2 border border-radius"
                >
                    <div v-for="text in input.texts" :key="text" v-text="text" />
                </radio-input>
            </div>
            <div class="flex justify-center">
                <router-link :to="route" class="btn">
                    Next
                </router-link>
            </div>
        </div>
    </card>
  </div>
</template>

<script>
export default {
    name: 'PriceLeadTime',
    data() {
        return {
            pltRoute: '',
            componentCounter: 0,
            radioInputs: {
                'transport.priceleadtime': [
                    {
                        to: '/transport/price-leadtime/template',
                        texts: ['Create booking', 'using template'],
                    },
                    {
                        to: '/transport/price-leadtime/form',
                        texts: ['Send price request', '& compare services'],
                    },
                ],
                'transport.transport-booking': [
                    {
                        to: '/transport/transport-booking/template',
                        texts: ['Create booking', 'using template'],
                    },
                    {
                        to: '/transport/transport-booking/ad-hoc',
                        texts: ['Ad-hoc booking'],
                    },
                    // {
                    //     to: '/transport/transport-booking/form',
                    //     texts: ['Book a', 'specific service'],
                    // },
                ],
            },
        };
    },
    computed: {
        route() {
            return this.pltRoute;
        },
    },
    watch: {
        $route(val, oldVal) {
            if (val.name === oldVal.name) {
                return;
            }

            this.pltRoute = this.radioInputs[this.$route.name][0].to;
            this.componentCounter++;
        },
    },
    created() {
        this.pltRoute = this.radioInputs[this.$route.name][0].to;
    },
}
</script>

<style lang="scss" scoped>
.price-lead-time {
    display: grid;
    place-content: center;

    .card {
        width: 500px;
    }
}
</style>